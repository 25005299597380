import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { Image, PaperContainer } from 'styledComponents/commonStyled';
import { threadoAISetting } from 'utils/menuData.js';
import { createStyles, makeStyles } from '@material-ui/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetCurrentCommunity,
  useGetPaymentStatus,
  useGetSidebarToggleState,
  useGetUserPermissions,
} from 'context/hooks';

import { colorToken } from 'theme/designToken';
import { Tooltip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import { GlobalStyles } from '@mui/material';
import { useLocation } from 'react-router';

import lockIconSvg from 'assets/images/icons/greyLockIcon.svg';
import { setUpgradeNowEventSourceAction } from 'redux/paymentsProvider/actions/paymentsActions';
import PaymentsModal from 'components/Payments/PaymentsModal';
import { trackUserEvent } from 'utils/segment';
import testBotIconActive from 'assets/images/icons/testBotIconActive.svg';
import {
  getAllHashtagsDataAction,
  getAllMentionsDataAction,
} from 'redux/editorProvider/actions/editorAction';
import { MenuListSidebarItem, MenuListSidebarItemText } from 'styledComponents/sidebarStyled';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 175,
      maxWidth: 400,
      backgroundColor: `#FFFFF`,
    },
    accordionExpand: {
      margin: '0 !important',
    },
  }),
);

export const formatNumberToCount = (n) => {
  if (n === undefined || n === null) return '';
  if (n == 0) return '0';
  if (n < 1e3) return n + '';
  if (n >= 1e3) return +(n / 1e3).toFixed(1) + 'K';
  return '';
};

function DisplayHeader({ name, isSubTitle, marginBottom, marginTop }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        marginTop: marginTop ? marginTop : '20px',
        marginBottom: marginBottom ? marginBottom : '18px',
        justifyContent: 'space-between',
        fontWeight: isSubTitle ? 500 : 500,
        fontSize: isSubTitle ? '12px' : '18px',
        lineHeight: '21px',
        color: isSubTitle ? '#000000' : '#000000',
      }}>
      <div>{name}</div>
    </div>
  );
}

const MenuItem = (props) => {
  const paymentsPlanStatus = useSelector(useGetPaymentStatus);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <MenuListSidebarItem
      selected={location.pathname === props?.url}
      onClick={() => {
        if (
          props?.paymentSubscription &&
          paymentsPlanStatus.status &&
          !props?.paymentSubscription.includes(paymentsPlanStatus.status)
        ) {
          props.setOpenPaymentsModal(true);
          trackUserEvent('Premium icon clicked', { source: 'Analytics Dashbaord' });
          dispatch(setUpgradeNowEventSourceAction('Analytics Dashbaord'));
        } else {
          props.setSelectedId(props.name);
          props.userNavigation(props.url, props.name);
        }
      }}>
      <MenuListSidebarItemText
        selected={location.pathname === props?.url}
        role="none"
        key={props.index}
        style={{
          width: props.name == 'Members' ? '80%' : '100%',
        }}>
        {props.iconSrc && (
          <Image
            src={location.pathname === props?.url ? props.activeIconSrc : props.iconSrc}
            height={15}
            width={15}
          />
        )}
        {props.name.length > 18 ? (
          <Tooltip title={props.name} followCursor>
            <span>{props.name}</span>
          </Tooltip>
        ) : (
          <span>{props.name}</span>
        )}
        {props?.paymentSubscription &&
          paymentsPlanStatus.status &&
          !props?.paymentSubscription.includes(paymentsPlanStatus.status) && (
            <Image src={lockIconSvg} height={10} width={10} />
          )}
      </MenuListSidebarItemText>
    </MenuListSidebarItem>
  );
};

function AccordionComponent(props) {
  return (
    <Accordion
      defaultExpanded={true}
      style={{
        margin: '18px 0',
      }}>
      <AccordionSummary
        className={props.name === 'Bot settings' && 'dashboard__sidebar-acc-summary'}
        expandIcon={<ExpandLessIcon />}
        aria-controls="panel1ab-content"
        id="panel1ab-header"
        style={{
          width: 'fit-content',
          padding: '5px 10px',
          height: '35px',
        }}>
        {props?.name === 'Bot settings' && (
          <Image src={testBotIconActive} alt="bot" style={{ marginRight: '8px' }} />
        )}
        <Typography
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
            color: '#000000',
          }}>
          {props.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          marginLeft: '0px',
          paddingLeft: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingRight: '0px',
          overflowX: 'scroll',
        }}>
        {props.subMenu &&
          props.subMenu.length > 0 &&
          props.subMenu.map(
            (subMenuIter, index) =>
              subMenuIter.name.length > 0 && (
                <MenuItem
                  key={index}
                  selectedId={props.selectedId}
                  setSelectedId={props.setSelectedId}
                  hoveredFilterId={props.hoveredFilterId}
                  setHoveredFilterId={props.setHoveredFilterId}
                  {...subMenuIter}
                  userNavigation={props.userNavigation}
                  setOpenPaymentsModal={props.setOpenPaymentsModal}></MenuItem>
              ),
          )}
      </AccordionDetails>
    </Accordion>
  );
}

const DashboardSideBar = ({ menuDataNew, open, userNavigation }) => {
  const sidebarToggleState = useSelector(useGetSidebarToggleState);
  const [openPaymentsModal, setOpenPaymentsModal] = React.useState(false);
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedId, setSelectedId] = useState();
  const location = useLocation();
  const communityData = useSelector(useGetCurrentCommunity);
  const dispatch = useDispatch();
  const [isThreadoAILogin, setThreadoAIlogin] = useState(false);
  const userPermissions = useSelector(useGetUserPermissions);
  const [userInfoPermissions, setUserInfoPermissions] = useState(null);
  // Effect for fetching permissions
  useEffect(() => {
    if (!communityData) {
      return;
    }
    if (!userPermissions) {
      return;
    }
    if (userPermissions) {
      if (!isEmpty(userPermissions)) {
        setUserInfoPermissions(userPermissions);
      }
    }
  }, [userPermissions, communityData]);

  useEffect(() => {
    if (!communityData || !userInfoPermissions) {
      return;
    }
    setThreadoAIlogin(false);
    if (communityData?.org?.signupType === 'THREADO_AI') {
      setThreadoAIlogin(true);
    } else {
      setThreadoAIlogin(false);
    }
  }, [userInfoPermissions, communityData, location.pathname]);

  useEffect(() => {
    if (!communityData?.id) return;
    // Load action and details for community
    dispatch(getAllMentionsDataAction(communityData?.id, ''));
    dispatch(getAllHashtagsDataAction(communityData?.id));
  }, [dispatch, communityData?.id]);

  /**
   * Function to populate filters based on route string
   * @param routeString This is the route as mentioned on MenuData
   */
  const getMenuByRoute = (routeString) => {
    const filteredMenu = menuDataNew.find((menu) => routeString.startsWith(menu.url));
    if (filteredMenu) {
      // Update the submenu if it's the 'Settings' menu and if it's a Threado AI login
      if (filteredMenu.name === 'Settings' && communityData?.org?.signupType === 'THREADO_AI') {
        filteredMenu.subMenu = threadoAISetting;
      }
      setSelectedMenu(filteredMenu);
    }
    return filteredMenu; // Return the found menu
  };

  useEffect(() => {
    getMenuByRoute(location.pathname);
  }, [location.pathname, menuDataNew, isThreadoAILogin]);

  useEffect(() => {
    selectedMenu?.subMenu.find((iter) => {
      switch (iter.type) {
        case 'MENU_LIST':
          if (iter.url === location.pathname) {
            setSelectedId(iter.name);
            return true;
          } else if (
            location.pathname.startsWith(iter.url) &&
            location.pathname.startsWith('/settings/integrations/slack')
          ) {
            setSelectedId(iter.name);
            return true;
          } else {
            return false;
          }
        case 'SUB_SECTION':
          return false;
        case 'SUB_TITLE_SECTION':
          return false;
        case 'ACCORDION':
          iter.subMenu.find((inst) => {
            if (inst.url === location.pathname) {
              setSelectedId(inst.name);
              return true;
            } else {
              return false;
            }
          });
      }
    });
  }, [selectedMenu]);

  const [hoveredFilterId, setHoveredFilterId] = useState();

  return (
    <>
      <GlobalStyles
        styles={{
          '.memberSideBar': {
            '& .MuiPaper-root.MuiAccordion-root ': {
              boxShadow: 'unset',
            },
            '& .MuiPaper-root.MuiAccordion-root::before ': {
              display: 'none',
            },
            '& div.MuiAccordionSummary-expandIconWrapper': {
              color: '#637da6',
            },
            '& .memberSideBar .MuiButtonBase-root.MuiAccordionSummary-root': {
              minHeight: 'unset',
              paddingLeft: '4px',
            },

            '& div.MuiAccordion-root.Mui-expanded': {
              margin: '0px',
            },

            '& .MuiAccordionSummary-expandIconWrapper': {
              marginLeft: '-5px',
            },

            '& .MuiAccordionSummary-content': {
              paddingLeft: '4px',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto !important',
              border: '1px solid #ffffff',
            },
            '& .MuiAccordionSummary-root:hover': {
              background: '#F5F6FD',
              borderRadius: '4px',
              border: '1px solid #F5F6FD',
              boxSizing: 'border-box',
            },

            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '12px 0px !important',
            },
          },
        }}
      />
      <PaperContainer
        className="memberSideBar"
        elevation={0}
        style={{
          borderRadius: colorToken.menuSidebar.secondarySidebar.border.radius,
          display: 'flex',
          alignItems: 'stretch',
          height: 'initial',
          maxHeight: '100vh',
          minHeight: '100vh',
          position: 'fixed',
          paddingLeft: !sidebarToggleState ? '75px' : '260px',
          top: 0,
          zIndex: 999,
          overflow: 'auto',
          backgroundColor: colorToken.menuSidebar.secondarySidebar.backgroundColor,
          paddingRight: '10px',
        }}>
        <Slide direction="right" in={open} easing={'easing.sharp'} mountOnEnter unmountOnExit>
          <div className={classes.root} style={{}}>
            {selectedMenu?.subMenu.map((iter, index) => {
              switch (iter.type) {
                case 'SUB_SECTION':
                  return <DisplayHeader {...iter} />;
                case 'SUB_TITLE_SECTION':
                  return (
                    <DisplayHeader
                      {...iter}
                      isSubTitle={true}
                      marginBottom={'6px'}
                      marginTop={'30px'}
                    />
                  );

                case 'MENU_LIST':
                  return (
                    <MenuItem
                      key={index}
                      name={selectedMenu.name}
                      parentMenu={selectedMenu.parentMenu}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      hoveredFilterId={hoveredFilterId}
                      setHoveredFilterId={setHoveredFilterId}
                      {...iter}
                      index={index}
                      userNavigation={userNavigation}
                      setOpenPaymentsModal={setOpenPaymentsModal}></MenuItem>
                  );

                case 'ACCORDION':
                  return (
                    <AccordionComponent
                      //   selectedMenu={selectedMenu}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      hoveredFilterId={hoveredFilterId}
                      setHoveredFilterId={setHoveredFilterId}
                      {...iter}
                      index={index}
                      userNavigation={userNavigation}
                      setOpenPaymentsModal={setOpenPaymentsModal}></AccordionComponent>
                  );
              }
            })}
          </div>
        </Slide>
      </PaperContainer>
      {openPaymentsModal && (
        <PaymentsModal
          openPaymentsModal={openPaymentsModal}
          handleClose={() => setOpenPaymentsModal(false)}
          defaultPlanIndex={1}
        />
      )}
    </>
  );
};

export default DashboardSideBar;
