import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Image } from 'styledComponents/commonStyled';
import {
  DISCORD_AUTH_URL,
  GITHUB_AUTH_URL,
  SLACK_AUTH_URL,
  TWITTER_AUTH_URL,
  ZOOM_AUTH_URL,
  CONFLUENCE_CONNECT_AUTH_URL,
  INSTALL_THREADO_FRESHDESK_APP_URL,
  NOTION_CONNECT_AUTH_URL,
  ZENDESK_CONNECT_AUTH_URL,
  HUBSPOT_AUTH_URL_FUNCTION,
  GOOGLE_DRIVE_CONNECT_AUTH_URL,
} from 'constant/urlConstants';
import {
  IntegrationReAuthorizeContainer,
  IntegrationReAuthorizeDetailsContainer,
  IntegrationReAuthorizeDetailsOuterContainer,
  IntegrationReFreshText,
  ReAuthorizeButton,
} from './IntegrationStyle';
import { primaryBackgroundColor } from 'theme/colorConstants';
import { useHistory } from 'react-router';
import { useGetCurrentCommunity } from 'context/hooks';
import { useAuth } from '../../../context/auth/AuthContext';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import FreshdeskConnectPopup from 'components/Chatbot/Settings/DataSource/Freshdesk/FreshdeskConnectPopup';

const IntegrationRow = ({ integration, communityId }) => {
  const { data, refreshText } = integration;
  const communityData = useSelector(useGetCurrentCommunity);
  const provider = data['@type'];
  const router = useHistory();
  const [freshdeskConnectPopup, setFreshdeskConnectPopup] = useState(false);
  let name,
    redirectLink,
    zoomData,
    twitterData,
    githubData,
    zendeskData,
    freshdeskData,
    confluenceData,
    notionData,
    google_driveData,
    hubspotData;
  switch (data['@type']) {
    case 'discord':
      name = integration.data.guildName;
      redirectLink = `${DISCORD_AUTH_URL}&community_id=${communityId}`;
      break;
    case 'slack':
      name = integration.data.teamName;
      redirectLink = `${SLACK_AUTH_URL}&community_id=${communityId}`;
      break;
    case 'zoom':
      zoomData = integration.data;
      name = `Zoom (${zoomData.name}|#${zoomData.accountId})`;
      redirectLink = `${ZOOM_AUTH_URL}&community_id=${communityId}`;
      break;
    case 'twitter':
      twitterData = integration.data;
      name = `Twitter (@${twitterData.userName})`;
      redirectLink = `${TWITTER_AUTH_URL}&community_id=${communityId}`;
      break;
    case 'github':
      githubData = integration.data;
      name = `Github (${githubData.name})`;
      redirectLink = `${GITHUB_AUTH_URL}&community_id=${communityId}`;
      break;
    case 'zendesk':
      zendeskData = integration.data;
      name = `Zendesk (${zendeskData.name})`;
      redirectLink = `${ZENDESK_CONNECT_AUTH_URL}?community_id=${communityId}&is_from_create=false&subdomain=${zendeskData.subdomain}&screenToRedirect=SUPPORT_BOT`;
      break;
    case 'freshdesk':
      freshdeskData = integration.data;
      name = `Freshdesk (${freshdeskData.name})`;
      redirectLink = `${INSTALL_THREADO_FRESHDESK_APP_URL}&community_id=${communityId}`;
      break;
    case 'confluence':
      confluenceData = integration.data;
      name = `Confluence (${confluenceData.name})`;
      redirectLink = `${CONFLUENCE_CONNECT_AUTH_URL}?community_id=${communityId}&is_from_create=false&signupType=${communityData?.org?.signupType}`;
      break;
    case 'notion':
      notionData = integration.data;
      name = `Notion (${notionData.name})`;
      redirectLink = `${NOTION_CONNECT_AUTH_URL}?community_id=${communityId}&is_from_create=false&signupType=${communityData?.org?.signupType}`;
      break;

    case 'hubspot':
      hubspotData = integration.data;
      name = `Hubspot (${hubspotData.name})`;
      redirectLink = HUBSPOT_AUTH_URL_FUNCTION(communityId);
      break;
    case 'google_drive':
      google_driveData = integration.data;
      name = `Google Drive (${google_driveData.name})`;
      redirectLink = `${GOOGLE_DRIVE_CONNECT_AUTH_URL}?community_id=${communityId}&is_from_create=false&signupType=${communityData?.org?.signupType}`;
      break;
    default:
      return <></>;
  }
  const redirectToTeamPage = () => {
    router.push('/settings/staff');
  };

  const getReconnectText = (providerType, name, data) => {
    console.log(data);
    let providerTypeLowerCase = providerType.toLowerCase();
    let onlyReauthProviders = [
      'Notion',
      'Confluence',
      'Zendesk',
      'Freshdesk',
      'Intercom',
      'Hubspot',
      'Google_Drive',
    ];
    if (
      onlyReauthProviders.map((iter) => iter.toLowerCase()).indexOf(providerTypeLowerCase) !== -1
    ) {
      return (
        <div>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
              color: '#191919',
            }}>
            We have added new features and fixed a few bugs which require you to re-authorise{` `}
            {data.domainName ? data.domainName : data.subdomain ? data.subdomain : data.name}
            {` `}integration. This will not cause any loss of data. <br />
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '13px',
              lineHeight: '20px',
              color: '#191919',
            }}>
            We have added new features and fixed a few bugs related to sending messages which
            require you to re-authorise Threado's {provider} Bot. This will not cause any loss of
            data. Once done, please reconnect your {name} account from{' '}
            <span
              role="none"
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={redirectToTeamPage}>{`Accounts > Profile `}</span>{' '}
            to start sending messages.
            <br />
          </span>
        </div>
      );
    }
  };
  const providerBasedReAuthActionHandler = (providerType, redirectLink) => {
    if (providerType.toLowerCase() === 'freshdesk') {
      setFreshdeskConnectPopup(true);
    } else {
      window.location = redirectLink;
    }
  };

  return (
    <>
      <IntegrationReAuthorizeContainer>
        <IntegrationReAuthorizeDetailsOuterContainer>
          <IntegrationReAuthorizeDetailsContainer>
            <div style={{ display: 'flex', paddingBottom: '28px' }}>
              <div style={{ minWidth: '50px', display: 'flex' }}>
                <Image src={`/icons/${provider}.svg`} width={20} height={20} alt={provider} />
              </div>
              {getReconnectText(data['@type'], name, data)}
            </div>
          </IntegrationReAuthorizeDetailsContainer>
        </IntegrationReAuthorizeDetailsOuterContainer>
        <div>
          <ReAuthorizeButton
            onClick={() => providerBasedReAuthActionHandler(data['@type'], redirectLink)}>
            Re-Authorise
          </ReAuthorizeButton>
        </div>
        <IntegrationReFreshText>{refreshText}</IntegrationReFreshText>
      </IntegrationReAuthorizeContainer>
      {freshdeskConnectPopup && (
        <FreshdeskConnectPopup
          open={freshdeskConnectPopup}
          handleClose={() => {
            setFreshdeskConnectPopup(false);
          }}
          isFromCreate={false}
        />
      )}
    </>
  );
};

const IntegrationTokenRefresh = ({ integrations, communityId }) => {
  const integrationsToRefresh = [];
  const providers = Object.keys(integrations);

  const { hardShutdown, shutdown } = useIntercom();

  const { logout } = useAuth();
  providers.forEach((provider) => {
    const providerIntegrations = integrations[provider];
    providerIntegrations.forEach((integration) => {
      if (integration.refresh) {
        integrationsToRefresh.push(integration);
      }
    });
  });
  const logoutHandler = () => {
    hardShutdown();
    shutdown();
    setTimeout(() => {
      logout();
    }, 0);
  };

  const getTitle = (providerType) => {
    let providerTypeLowerCase = providerType.toLowerCase();
    let onlyReauthProviders = [
      'Notion',
      'Confluence',
      'Zendesk',
      'Freshdesk',
      'Intercom',
      'Hubspot',
      'Google_Drive',
    ];
    if (
      onlyReauthProviders.map((iter) => iter.toLowerCase()).indexOf(providerTypeLowerCase) !== -1
    ) {
      return `Re-authorise your ${providerType} account`;
    } else {
      return `Re-authorise your ${provider} ${
        integrationsToRefresh.length > 1 ? 'Integrations' : 'Integration'
      } and Re-connect your ${provider} account`;
    }
  };

  const { data } = integrationsToRefresh[0];
  const provider = data['@type'];
  return (
    <>
      <Dialog open={true} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{getTitle(provider)}</div>
            <button
              style={{ fontSize: '12px', border: 'none', background: 'none' }}
              onClick={logoutHandler}>
              LOGOUT
            </button>
          </div>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: primaryBackgroundColor }}>
          <DialogContentText>
            {integrationsToRefresh.map((integration, index) => {
              return (
                <IntegrationRow integration={integration} key={index} communityId={communityId} />
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IntegrationTokenRefresh;
