import Api from 'layers/interceptor/interceptor';
import { GET_ALL_MENTIONS, GET_ALL_TAGS, SET_INTIAL_MESSAGE } from 'redux/editorProvider/types';
import { getMembersForMentions, getChannelsForHashtag } from 'constant/apiConstants';

/**
 * Method to get all the mention users
 * @param communityId
 * @param searchQuery
 * @returns
 */
export const getAllMentionsDataAction = (communityId, searchQuery) => (dispatch) => {
  Api.get(getMembersForMentions(communityId, searchQuery))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_ALL_MENTIONS,
        payload: res.content || [],
      });
    })
    .catch(() => {
      dispatch({
        type: GET_ALL_MENTIONS,
        payload: [],
      });
    });
};

/**
 * Method to get all the mention users
 * @param communityId
 * @param searchQuery
 * @returns
 */
export const getAllMentionsDataActionPromise = (communityId, searchQuery) => {
  return new Promise((resolve, reject) => {
    Api.get(getMembersForMentions(communityId, searchQuery))
      //eslint-disable-next-line
      .then((res) => {
        resolve(
          res.content.map((inst) => {
            return { value: inst.id, label: inst.name || inst.username, ...inst };
          }),
        );
      });
  });
};
/**
 * Method to fetch all the channels
 * @param communityId
 * @returns
 */
export const getAllHashtagsDataAction = (communityId) => (dispatch) => {
  // dispatch(setActivityIsLoading());
  Api.get(getChannelsForHashtag(communityId))
    //eslint-disable-next-line
    .then((res) => {
      dispatch({
        type: GET_ALL_TAGS,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_ALL_TAGS,
        payload: [],
      });
    });
};

export const setSelectedActivityAction = (message) => ({
  type: SET_INTIAL_MESSAGE,
  payload: message,
});

export const setIsIntialPrefillRequired = (isPrefillRequired) => ({
  type: SET_INTIAL_MESSAGE,
  payload: isPrefillRequired,
});
