import { AnyAction } from 'redux';
import {
  GET_ALL_MENTIONS,
  GET_ALL_TAGS,
  IS_INITIAL_MESSAGE_SET,
  SET_INTIAL_MESSAGE,
} from '../types';

const initialState = {
  allMentions: [],
  allHashtags: [],
  intialMessage: '',
  isInitialPrefillRequired: false,
};

const editorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_MENTIONS: {
      console.log(payload);
      let allMentions = payload.map((iter) => {
        return {
          ...iter,
          value: iter.username,
        };
      });
      allMentions.push({ id: 0, value: 'all', name: 'all' });
      localStorage.setItem('_mentions', JSON.stringify(allMentions));
      return {
        ...state,
        allMentions: allMentions,
      };
    }
    case GET_ALL_TAGS: {
      let allTags = payload.map((iter) => {
        return {
          ...iter,
          value: iter.name,
        };
      });
      allTags.push({ id: 0, value: 'all', name: 'all' });
      localStorage.setItem('_tags', JSON.stringify(allTags));
      return {
        ...state,
        allHashtags: allTags,
      };
    }

    case SET_INTIAL_MESSAGE:
      return {
        ...state,
        intialMessage: payload,
      };
    case IS_INITIAL_MESSAGE_SET:
      return {
        ...state,
        isInitialPrefillRequired: payload,
      };
    default:
      return state;
  }
};

export default editorReducer;
