import billingIconActive from 'assets/images/icons/secondarySidebar/billingActive.svg';
import communitiesIconActive from 'assets/images/icons/secondarySidebar/communitiesActive.svg';
import companyIconActive from 'assets/images/icons/secondarySidebar/companyIconActive.svg';
import integrationsIconActive from 'assets/images/icons/secondarySidebar/IntegrationsActive.svg';
import teamsIconActive from 'assets/images/icons/secondarySidebar/teamsActive.svg';
import moduleIconActive from 'assets/images/icons/secondarySidebar/moduleIconActive.svg';
import summaryPageIcon from 'assets/images/icons/summaryPageIcon.svg';
import summaryPageIconActive from 'assets/images/icons/summaryPageIconActive.svg';
import retentionPageIcon from 'assets/images/icons/retentionPageIcon.svg';
import analyticsActivityIcon from 'assets/images/icons/analyticsActivityIcon.svg';
import analyticsChannelIcon from 'assets/images/icons/analyticsChannelIcon.svg';
import analyticsSentimentIcon from 'assets/images/icons/analyticsSentimentIcon.svg';
import analyticsTopicIcon from 'assets/images/icons/analyticsTopicIcon.svg';
import analyticsAtRiskIcon from 'assets/images/icons/analyticsAtRiskIcon.svg';
import analyticsTeamPerformanceIcon from 'assets/images/icons/analyticsTeamPerformanceIcon.svg';
import analyticsResponsivenessIcon from 'assets/images/icons/analyticsResponsivenessIcon.svg';

// Member analytics sidebar icons
import newMembersIcon from 'assets/images/icons/analytics/members/newMembersSB.svg';
import totalMembersIcon from 'assets/images/icons/analytics/members/totalMemberSB.svg';
import topMembersIcon from 'assets/images/icons/analytics/members/topMembersSB.svg';
import potentialChampsIcon from 'assets/images/icons/analytics/members/potentialChampsSB.svg';

import teamMateInactive from 'assets/images/icons/teamMateInactive.svg';
import teamMateActive from 'assets/images/icons/teamMateActive.svg';
import joinCommunityInactive from 'assets/images/icons/joinCommunityInactive.svg';

import botcustomization from 'assets/images/icons/botcustomization.svg';

import allConversation from 'assets/images/icons/allconversation.svg';
import allconversationActive from 'assets/images/icons/allconversationActive.svg';

import openConversation from 'assets/images/icons/openConversation.svg';
import openConversationActive from 'assets/images/icons/openConversationActive.svg';
import resolvedConversation from 'assets/images/icons/resolvedConversation.svg';
import resolvedConversationActive from 'assets/images/icons/resolvedConversationActive.svg';

import dataSourceBotIcon from 'assets/images/icons/dataSourceBotIcon.svg';

import trainIcon from 'assets/images/icons/trainIcon.svg';
import trainIconActive from 'assets/images/icons/trainIconActive.svg';
import agentAssistAnalyticsIcon from 'assets/images/icons/agentAssistAnalytics.svg';
import customerBotAnalyticsIcon from 'assets/images/icons/customerBotAnalytics.svg';
import aisettingModuleIcon from 'assets/images/icons/aisettingModuleIcon.svg';

import { APP_FEATURE_SETTINGS_BILLING, APP_FEATURE_WORKFLOW } from '../constant/features';
import { SubscriptionStatusType } from 'components/Settings/BillDetails/utils';

import setupIcon from 'assets/images/icons/setupIcon.svg';
import setupIconActive from 'assets/images/icons/setupIconActive.svg';
import slackBotMenu from 'assets/images/icons/slackBotMenu.svg';
import slackBotMenuActive from 'assets/images/icons/slackBotMenuActive.svg';
import chomeMenuItem from 'assets/images/icons/chomeMenuItem.svg';
import chomeMenuItemActive from 'assets/images/icons/chomeMenuItemActive.svg';
import webMenuItem from 'assets/images/icons/webMenuItem.svg';
import webMenuItemActive from 'assets/images/icons/webMenuItemActive.svg';
import discordMenuItem from 'assets/images/icons/discordMenuItem.svg';
import discordMenuItemActive from 'assets/images/icons/discordMenuItemActive.svg';
import askAiIcon from 'assets/images/icons/askAI.svg';
import domainVerficationPageIcon from 'assets/images/icons/domainVerficationPageIcon.svg';
import rewardCenterIcon from 'assets/images/icons/rewardCenterIcon.svg';
import bellIcon from 'assets/images/icons/bellIcon.svg';
import securitySettingsIcon from 'assets/images/icons/securitySettingsIcon.svg';
import msTeamsIcon from 'assets/images/icons/msTeamsSidebarIcon.svg';
import msTeamsIconActive from 'assets/images/icons/msTeamsSidebarIconActive.svg';

export const menuDataForBot = [
  {
    name: 'Train',
    url: '/support-bot/training',
    permissionKey: 'BOT_TRAIN',
    restricted: process.env.REACT_APP_ENABLE_AGENTS_ASSIST === '1' ? false : true,
    feature: '',
    iconSrc: trainIcon,
    activeIconSrc: trainIconActive,
    redirectionUrl: '/support-bot/training',
    hasSubMenu: false,
    subMenu: [],
  },
  {
    name: 'Ask',
    url: '/support-bot/ask',
    permissionKey: 'BOT_ASK',
    restricted: false,
    feature: '',
    iconSrc: askAiIcon,
    activeIconSrc: askAiIcon,
    redirectionUrl: '/support-bot/ask',
    hasSubMenu: false,
    subMenu: [],
  },
  {
    name: 'Setup',
    url: '/support-bot/setup',
    permissionKey: 'BOT_TRAIN',
    restricted: process.env.REACT_APP_ENABLE_AGENTS_ASSIST === '1' ? false : true,
    feature: '',
    iconSrc: setupIcon,
    activeIconSrc: setupIconActive,
    redirectionUrl: '/support-bot/setup',
    hasSubMenu: false,
    subMenu: [],
    hasTreeMenu: true,
    treeMenu: [
      {
        name: 'Slack bot',
        url: '/support-bot/setup/slack-bot',
        restricted: false,
        feature: '',
        type: 'MENU_LIST',
        iconSrc: slackBotMenu,
        activeIconSrc: slackBotMenuActive,
      },
      {
        name: 'Chrome app',
        url: '/support-bot/setup/chrome-app',
        restricted: false,
        feature: '',
        type: 'MENU_LIST',
        iconSrc: chomeMenuItem,
        activeIconSrc: chomeMenuItemActive,
      },
      {
        name: 'Web chat',
        url: '/support-bot/setup/web-chat',
        restricted: false,
        feature: '',
        type: 'MENU_LIST',
        iconSrc: webMenuItem,
        activeIconSrc: webMenuItemActive,
      },
      {
        name: 'Discord bot',
        url: '/support-bot/setup/discord-bot',
        restricted: false,
        feature: '',
        type: 'MENU_LIST',
        iconSrc: discordMenuItem,
        activeIconSrc: discordMenuItemActive,
      },
      // {
      //   name: 'Microsoft teams',
      //   url: '/support-bot/setup/ms-teams',
      //   restricted: false,
      //   feature: '',
      //   type: 'MENU_LIST',
      //   iconSrc: msTeamsIcon,
      //   activeIconSrc: msTeamsIconActive,
      // },
    ],
  },

  {
    name: 'Analytics',
    url: '/support-bot/insights',
    permissionKey: 'BOT_ANALYTICS',
    restricted: false,
    feature: '',
    iconSrc: dataSourceBotIcon,
    activeIconSrc: dataSourceBotIcon,
    redirectionUrl: '/support-bot/insights',
    hasSubMenu: false,
    subMenu: [],
    hasTreeMenu: false,
  },
  {
    name: 'Conversations',
    url: '/support-bot/conversation-feed',
    restricted: false,
    permissionKey: '',
    feature: '',
    iconSrc: botcustomization,
    activeIconSrc: botcustomization,
    redirectionUrl: '/support-bot/conversation-feed',
    hasSubMenu: false,
    subMenu: [],
    // treeMenu: [
    //   {
    //     name: 'Open',
    //     url: '/support-bot/conversations/open',
    //     restricted: false,
    //     activeIconSrc: openConversationActive,
    //     feature: '',
    //     iconSrc: openConversation,
    //     type: 'MENU_LIST',
    //     subMenu: [],
    //   },
    //   {
    //     name: 'Resolved',
    //     url: '/support-bot/conversations/resolved',
    //     restricted: false,
    //     feature: '',
    //     iconSrc: resolvedConversation,
    //     activeIconSrc: resolvedConversationActive,
    //     type: 'MENU_LIST',
    //     subMenu: [],
    //   },
    //   {
    //     name: 'All conversations',
    //     url: '/support-bot/conversations/all',
    //     restricted: false,
    //     feature: '',
    //     iconSrc: allConversation,
    //     activeIconSrc: allconversationActive,
    //     type: 'MENU_LIST',
    //     subMenu: [],
    //   },
    // ],
  },
];

export const menuDataNew = [
  {
    name: 'Dashboard',
    iconSrc: 'activityIcon.svg',
    activeIconSrc: 'activityActiveIcon.svg',
    url: '/activity',
    redirectionUrl: '/activity/summary',
    restricted: false,
    feature: '',
    hasSubMenu: true,
    permissionKey: 'DASHBOARD',
    subMenu: [
      {
        name: 'Dashboard',
        type: 'SUB_SECTION',
      },
      {
        name: 'Summary',
        url: '/activity/summary',
        restricted: false,
        feature: '',
        iconSrc: summaryPageIcon,
        activeIconSrc: summaryPageIconActive,
        type: 'MENU_LIST',
      },
      {
        name: 'Reports',
        type: 'SUB_SECTION',
      },
      {
        name: 'Members',
        url: '/activity/members',
        restricted: false,
        feature: '',
        iconSrc: summaryPageIcon,
        activeIconSrc: summaryPageIconActive,
        type: 'ACCORDION',
        subMenu: [
          {
            name: 'Total members',
            url: '/activity/total-members',
            restricted: false,
            feature: '',
            iconSrc: totalMembersIcon,
            activeIconSrc: totalMembersIcon,
          },
          {
            name: 'New members',
            url: '/activity/new-members',
            restricted: false,
            feature: '',
            iconSrc: newMembersIcon,
            activeIconSrc: newMembersIcon,
          },
          {
            name: 'Top members',
            url: '/activity/top-members',
            restricted: false,
            feature: '',
            iconSrc: topMembersIcon,
            activeIconSrc: topMembersIcon,
          },
          {
            name: 'Potential champions',
            url: '/activity/potential-champions',
            restricted: false,
            feature: '',
            iconSrc: potentialChampsIcon,
            activeIconSrc: potentialChampsIcon,
          },
        ],
      },
      {
        name: 'Activities',
        url: '/activity/activities',
        restricted: false,
        feature: '',
        iconSrc: summaryPageIcon,
        activeIconSrc: summaryPageIconActive,
        type: 'ACCORDION',
        subMenu: [
          {
            name: 'Activities',
            url: '/activity/activities',
            restricted: false,
            feature: '',
            iconSrc: analyticsActivityIcon,
            activeIconSrc: analyticsActivityIcon,
          },
          {
            name: 'Channel',
            url: '/activity/channels',
            restricted: false,
            feature: '',
            iconSrc: analyticsChannelIcon,
            activeIconSrc: analyticsChannelIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
          {
            name: 'Sentiment',
            url: '/activity/sentiments',
            restricted: false,
            feature: '',
            iconSrc: analyticsSentimentIcon,
            activeIconSrc: analyticsSentimentIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
          {
            name: 'Topics',
            url: '/activity/topics',
            restricted: false,
            feature: '',
            iconSrc: analyticsTopicIcon,
            activeIconSrc: analyticsTopicIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
        ],
      },
      {
        name: 'Retention',
        url: '/analytics/retention',
        restricted: false,
        feature: '',
        iconSrc: summaryPageIcon,
        activeIconSrc: summaryPageIconActive,
        type: 'ACCORDION',
        subMenu: [
          {
            name: 'Retention',
            url: '/activity/retention',
            restricted: false,
            feature: '',
            iconSrc: retentionPageIcon,
            activeIconSrc: retentionPageIcon,
          },
          {
            name: 'At risk members',
            url: '/activity/at-risk-members',
            restricted: false,
            feature: '',
            iconSrc: analyticsAtRiskIcon,
            activeIconSrc: analyticsAtRiskIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
        ],
      },
      {
        name: 'Engagement',
        url: '/activity/engagement',
        restricted: false,
        feature: '',
        iconSrc: summaryPageIcon,
        activeIconSrc: summaryPageIconActive,
        type: 'ACCORDION',
        subMenu: [
          {
            name: 'Team performance',
            url: '/activity/team-performance',
            restricted: false,
            feature: '',
            iconSrc: analyticsTeamPerformanceIcon,
            activeIconSrc: analyticsTeamPerformanceIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
          {
            name: 'Responsiveness',
            url: '/activity/responsiveness',
            restricted: false,
            feature: '',
            iconSrc: analyticsResponsivenessIcon,
            activeIconSrc: analyticsResponsivenessIcon,
            paymentSubscription: [
              SubscriptionStatusType.PAID,
              SubscriptionStatusType.TRIAL,
              SubscriptionStatusType.CANCELLED,
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Members',
    iconSrc: 'memberInactive.svg',
    activeIconSrc: 'memberActive.svg',
    url: '/members',
    restricted: false,
    feature: '',
    hasSubMenu: true,
    redirectionUrl: '/members',
    permissionKey: 'MEMBER_CRM',
    subMenu: [],
  },
  {
    name: 'Automation',
    iconSrc: 'automationIcon.svg',
    activeIconSrc: 'automationActiveIcon.svg',
    url: '/automations',
    redirectionUrl: '/automations',
    hasSubMenu: false,
    restricted: true,
    feature: APP_FEATURE_WORKFLOW,
    permissionKey: 'AUTOMATION',
    subMenu: [],
  },
  {
    name: 'Outreach',
    identifier: 'OUTREACH', // INFO:Do not remove
    iconSrc: 'outreachIcon.svg',
    activeIconSrc: 'outreachActive.svg',
    url: '/outreach',
    // redirectionUrl: '/outreach',
    hasSubMenu: false,
    restricted: false,
    feature: '',
    permissionKey: 'OUTREACH',
    subMenu: [],
  },
  {
    name: 'Activities',
    iconSrc: 'messageIcon.svg',
    activeIconSrc: 'messagesActive.svg',
    url: '/activities',
    restricted: false,
    feature: '',
    hasSubMenu: true,
    redirectionUrl: '/activities',
    permissionKey: 'ACTIVITIES',
    subMenu: [
      {
        name: '',
        url: '/activities',
        restricted: false,
        feature: '',
      },
    ],
  },
  {
    name: 'Activities',
    iconSrc: 'messageIcon.svg',
    activeIconSrc: 'messagesActive.svg',
    url: '/support',
    restricted: true,
    feature: '',
    hasSubMenu: true,
    redirectionUrl: '/support',
    subMenu: [
      {
        name: '',
        url: '/support',
        restricted: false,
        feature: '',
      },
    ],
  },
];

export const metaDataMenu = [
  {
    name: 'Add teammates',
    iconSrc: teamMateInactive,
    activeIconSrc: teamMateActive,
    url: '/add-teammate',
    hasSubMenu: false,
    restricted: false,
    feature: '',
    subMenu: [],
  },
  {
    name: 'Join community',
    iconSrc: joinCommunityInactive,
    activeIconSrc: joinCommunityInactive,
    url: 'https://www.threado.com/community#Join',
    hasSubMenu: false,
    restricted: true,
    feature: '',
    subMenu: [],
  },
  {
    name: 'Notifications',
    permissionKey: 'NOTIFICATIONS',
    iconSrc: 'bellIcon.svg',
    activeIconSrc: 'notificationIconActive.svg',
    url: '/logging/notifications',
    hasSubMenu: false,
    restricted: true,
    feature: '',
    subMenu: [],
  },
  {
    name: 'Settings',
    iconSrc: 'settingIcon.svg',
    permissionKey: 'SETTINGS',
    activeIconSrc: 'settingsActive.svg',
    url: '/settings',
    redirectionUrl: '/settings/community',
    hasSubMenu: true,
    restricted: false,
    feature: '',
    subMenu: [
      {
        name: 'Settings',
        type: 'SUB_SECTION',
        permissionKey: 'SETTINGS',
      },
      {
        name: 'Organisation',
        type: 'SUB_TITLE_SECTION',
        permissionKey: 'SETTINGS_COMPANY',
      },
      {
        name: 'Company',
        url: '/settings/company',
        restricted: false,
        feature: '',
        iconSrc: companyIconActive,
        activeIconSrc: companyIconActive,
        type: 'MENU_LIST',
        permissionKey: 'SETTINGS_COMPANY',
      },
      {
        name: 'Billing',
        url: '/settings/billing',
        restricted: false,
        feature: APP_FEATURE_SETTINGS_BILLING,
        iconSrc: billingIconActive,
        activeIconSrc: billingIconActive,
        type: 'MENU_LIST',
        permissionKey: 'BILLING',
      },
      {
        name: 'Workspace',
        type: 'SUB_TITLE_SECTION',
        permissionKey: 'SETTINGS_WORKSPACE',
      },
      {
        name: 'Details',
        url: '/settings/community',
        restricted: false,
        feature: '',
        iconSrc: communitiesIconActive,
        activeIconSrc: communitiesIconActive,
        type: 'MENU_LIST',
        permissionKey: 'SETTINGS_WORKSPACE',
      },
      {
        name: 'AI settings',
        url: '/settings/ai',
        restricted: false,
        feature: '',
        iconSrc: aisettingModuleIcon,
        activeIconSrc: aisettingModuleIcon,
        type: 'MENU_LIST',
        permissionKey: 'BOT_TRAIN',
      },
      {
        name: 'Integrations',
        url: '/settings/integrations',
        restricted: false,
        feature: '',
        iconSrc: integrationsIconActive,
        activeIconSrc: integrationsIconActive,
        type: 'MENU_LIST',
        permissionKey: 'SETTINGS_INTEGRATIONS',
      },
      {
        name: 'Team',
        url: '/settings/staff',
        restricted: false,
        feature: '',
        iconSrc: teamsIconActive,
        activeIconSrc: teamsIconActive,
        type: 'MENU_LIST',
        permissionKey: 'SETTINGS_TEAM',
      },
      {
        name: 'Security',
        type: 'SUB_TITLE_SECTION',
        permissionKey: 'SETTINGS_WORKSPACE',
      },
      {
        name: 'Security settings',
        url: '/settings/security',
        restricted: false,
        feature: '',
        iconSrc: securitySettingsIcon,
        activeIconSrc: securitySettingsIcon,
        type: 'MENU_LIST',
        permissionKey: 'SETTINGS_WORKSPACE',
      },
      {
        name: 'Community',
        type: 'SUB_TITLE_SECTION',
        permissionKey: 'OUTREACH',
      },
      {
        name: 'Domain verification',
        url: '/settings/domain-verification',
        restricted: false,
        feature: '',
        iconSrc: domainVerficationPageIcon,
        activeIconSrc: domainVerficationPageIcon,
        type: 'MENU_LIST',
        permissionKey: 'OUTREACH',
      },
      {
        name: 'Rewards center',
        url: '/settings/rewardscenter',
        restricted: false,
        feature: '',
        iconSrc: rewardCenterIcon,
        activeIconSrc: rewardCenterIcon,
        type: 'MENU_LIST',
        permissionKey: 'OUTREACH',
      },
      {
        name: 'Slack notifications',
        url: '/settings/notifications',
        restricted: false,
        feature: '',
        iconSrc: bellIcon,
        activeIconSrc: bellIcon,
        type: 'MENU_LIST',
        permissionKey: 'OUTREACH',
      },
    ],
  },
];

export const threadoAISetting = [
  {
    name: 'Settings',
    type: 'SUB_SECTION',
    permissionKey: 'SETTINGS',
  },
  {
    name: 'Organisation',
    type: 'SUB_TITLE_SECTION',
    permissionKey: 'SETTINGS_COMPANY',
  },
  {
    name: 'Company',
    url: '/settings/company',
    restricted: false,
    feature: '',
    iconSrc: companyIconActive,
    activeIconSrc: companyIconActive,
    type: 'MENU_LIST',
    permissionKey: 'SETTINGS_COMPANY',
  },
  {
    name: 'Billing',
    url: '/settings/billing',
    restricted: false,
    feature: APP_FEATURE_SETTINGS_BILLING,
    iconSrc: billingIconActive,
    activeIconSrc: billingIconActive,
    type: 'MENU_LIST',
    permissionKey: 'BILLING',
  },
  {
    name: 'Workspace',
    type: 'SUB_TITLE_SECTION',
    permissionKey: 'SETTINGS_WORKSPACE',
  },
  {
    name: 'Details',
    url: '/settings/community',
    restricted: false,
    feature: '',
    iconSrc: communitiesIconActive,
    activeIconSrc: communitiesIconActive,
    type: 'MENU_LIST',
    permissionKey: 'SETTINGS_WORKSPACE',
  },
  {
    name: 'AI settings',
    url: '/settings/ai',
    restricted: false,
    feature: '',
    iconSrc: aisettingModuleIcon,
    activeIconSrc: aisettingModuleIcon,
    type: 'MENU_LIST',
    permissionKey: 'BOT_TRAIN',
  },
  {
    name: 'Integrations',
    url: '/settings/integrations',
    restricted: false,
    feature: '',
    iconSrc: integrationsIconActive,
    activeIconSrc: integrationsIconActive,
    type: 'MENU_LIST',
    permissionKey: 'SETTINGS_INTEGRATIONS',
  },
  {
    name: 'Team',
    url: '/settings/staff',
    restricted: false,
    feature: '',
    iconSrc: teamsIconActive,
    activeIconSrc: teamsIconActive,
    type: 'MENU_LIST',
    permissionKey: 'SETTINGS_TEAM',
  },
  {
    name: 'Security',
    type: 'SUB_TITLE_SECTION',
    permissionKey: 'SETTINGS_WORKSPACE',
  },
  {
    name: 'Security settings',
    url: '/settings/security',
    restricted: false,
    feature: '',
    iconSrc: securitySettingsIcon,
    activeIconSrc: securitySettingsIcon,
    type: 'MENU_LIST',
    permissionKey: 'BOT_TRAIN',
  },
];
