import { Dispatch } from 'redux';
import * as Yup from 'yup';
import {
  deleteIntegrationAction,
  saveCommunityAction,
  saveCreateOrganisationAction,
  discourseIntegrationPostAction,
  getCommunityPlatformDetails,
  updateCommunityAction,
  deleteIntegrationInternalAction,
  freshdeskIntegrationPostAction,
} from 'redux/communityProvider/actions/communityAction';
import { IntegrationType } from 'redux/contentSchedulerProvider/types';
import { ALLOWED_PROTOCOLS_LIST, extractValueFromSubdomain } from 'components/Chatbot/utils';
import threadoAILogo from 'assets/images/icons/threadoAIBotLogo.svg';
import azureLogo from 'assets/images/icons/azuerAiLogo.svg';
import chatGptLogo from 'assets/images/icons/chatGptLogo.svg';
import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
import { urlRegex } from 'utils/common';

export const communitySchema = Yup.object().shape({
  organisationName: Yup.string().required('Please enter Organisation name.'),
  communityName: Yup.string()
    .trim()
    .required('Workspace name field cannot be empty')
    .test('no-url', 'Workspace name field cannot contain a URL', (value) => !urlRegex.test(value)),
  communityTimezone: Yup.string().required('Please enter community timezone.'),
});

export const createCommunitySchema = Yup.object().shape({
  organisationName: Yup.string()
    .matches(
      /^[aA-zZ\s0-9 \,\.\-\;\'\&]+$/,
      "Only alphabets and few special characters allowed- , (comma) - (hyphen) ' (apostophe) . (full stop) ; (semi-colon) & (ampersand)",
    )
    .trim()
    .required('Company name field cannot be empty'),
  communityName: Yup.string()
    .trim()
    .required('Workspace name field cannot be empty')
    .test('no-url', 'Workspace name field cannot contain a URL', (value) => !urlRegex.test(value)),
  communityTimezone: Yup.string().required('Please enter community timezone.'),
  communityPlatforms: Yup.array()
    .min(1, 'Please enter atleast one community platform')
    .required('Please enter atleast one community platform'),
  communitySize: Yup.string().trim().required('Please enter community size'),
});

export const createCommunitySchemaForBotOnlyFlow = Yup.object().shape({
  organisationName: Yup.string()
    .matches(
      /^[aA-zZ\s0-9 \,\.\-\;\'\&]+$/,
      "Only alphabets and few special characters allowed- , (comma) - (hyphen) ' (apostophe) . (full stop) ; (semi-colon) & (ampersand)",
    )
    .trim()
    .required('Company name field cannot be empty'),

  discoveredPlatform: Yup.string().trim().required('Please select discovered platform'),
});

export const createOrganisationSchema = Yup.object().shape({
  organisationName: Yup.string().required('Please enter organisation name'),
});

export const discourseIntegrationSchema = Yup.object().shape({
  discourseDomain: Yup.string()
    .url('Your discourse domain must be a valid URL')
    .required('Please enter your discourse domain.'),
  apiKey: Yup.string().required('Please enter your API key.'),
  apiUsername: Yup.string().required('Please enter your Discourse username.'),
});

export const selectAiModelSchema = Yup.object().shape({
  apiKey: Yup.string()
    .min(20, 'Must be more than 20 characters')
    .required('Please enter your API key.'),
});

export const selectAiModelSchemaWithEndpoint = Yup.object().shape({
  apiKey: Yup.string()
    .min(20, 'Must be more than 20 characters')
    .required('Please enter your API key.'),
  apiEndpoint: Yup.string()
    .min(20, 'Must be more than 20 characters')
    .required('Please enter your API endpoint.'),
  deploymentName: Yup.string()
    .min(2, 'Must be more than 2 characters')
    .required('Please enter your Deployment name'),
});
export const submitFormAction = async (
  data,
  //eslint-disable-next-line
  dispatch,
  id,
) => {
  await dispatch(
    saveCommunityAction({
      id: id,
      name: data.communityName.trim(),
      description: data.communityDescription,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // INFO : we set timezone directly from browser
      organisationName: data.organisationName.trim(),
      organisationId: data.organisationId,
      communityPlatforms: (data.communityPlatforms || [])
        .map((inst) => inst.value)
        .filter((inst) => {
          if (typeof inst == 'string') {
            return true;
          }
        }),
      size: data.communitySize,
      communityPlatformsIds: (data.communityPlatforms || [])
        .map((inst) => inst.value)
        .filter((inst) => {
          if (typeof inst == 'number') {
            return true;
          }
        }),
      signupType: data?.signupType,
      discoveredPlatform: data?.discoveredPlatform,
      phoneNumber: data?.phoneNumber
    }),
  );
};

export const submitFormUpdateAction = async (
  data,
  //eslint-disable-next-line
  dispatch,
  id,
) => {
  await dispatch(
    updateCommunityAction({
      id: id,
      name: data.communityName.trim(),
      description: data.communityDescription,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // INFO : we set timezone directly from browser
      organisationName: data.organisationName.trim(),
      organisationId: data.organisationId,
      communityPlatforms: (data.communityPlatforms || [])
        .map((inst) => inst.value)
        .filter((inst) => {
          if (typeof inst == 'string') {
            return true;
          }
        }),
      size: data.communitySize,
      communityPlatformsIds: (data.communityPlatforms || [])
        .map((inst) => inst.value)
        .filter((inst) => {
          if (typeof inst == 'number') {
            return true;
          }
        }),
      signupType: data?.signupType,
      discoveredPlatform: data?.discoveredPlatform,
      phoneNumber: data?.phoneNumber
    }),
  );
};

export const submitFormUpdateNewAction = async (
  data,
  //eslint-disable-next-line
  dispatch,
  id,
) => {
  await dispatch(
    updateCommunityAction({
      id: data.id,
      name: data.name.trim(),
      description: data.description,
      timezone: data.timezone,
      organisationName: data.organizationName.trim(),
      organisationId: data.organizationId,
      communityPlatforms: data.communityPlatformIds,
      size: data?.size,
      communityPlatformsIds: data.communityPlatformIds,
      signupType: data?.signupType,
      discoveredPlatform: data?.discoveredPlatform,
    }),
  );
};

export const getCommunityPlatformDetailsUtils = async (dispatch) => {
  await dispatch(getCommunityPlatformDetails());
};

export const submitFormCreateOrganisation = async (
  data,
  //eslint-disable-next-line
  dispatch,
  userId,
) => {
  await dispatch(
    saveCreateOrganisationAction(userId, {
      name: data.organisationName,
    }),
  );
};

export const onDeleteIntegration = async (
  integrationId,
  //eslint-disable-next-line
  dispatch,
) => {
  await dispatch(deleteIntegrationAction(integrationId));
};

export const onDeleteIntegrationInternal = async (integrationId, dispatch) => {
  await dispatch(deleteIntegrationInternalAction(integrationId));
};

export const submitFormDiscourseAction = async (
  data,
  domainHostname,
  //eslint-disable-next-line
  dispatch,
  id,
  integrationSecret,
) => {
  await dispatch(
    discourseIntegrationPostAction(
      {
        apiSecret: integrationSecret,
        apiKey: data.apiKey,
        apiUsername: data.apiUsername,
        domain: domainHostname,
      },
      id,
    ),
  );
};

export const submitFormFreshdeskAction = async (data, dispatch, id) => {
  await dispatch(
    freshdeskIntegrationPostAction(
      {
        domain: data.domain,
        apiKey: data.apiKey,
        apiUsername: data.apiUsername,
      },
      id,
    ),
  );
};
export const TOP_EMAIL_LIST = [
  'gmail',
  'yahoo',
  'hotmail',
  'aol',
  'hotmail',
  'msn',
  'yahoo',
  'wanadoo',
  'orange',
  'comcast',
  'yahoo',
];

// Get the domain Name from Email
export const extractOrganisationName = (email) => {
  email = email.replace(/.*@/, '').split('.')[0];
  return TOP_EMAIL_LIST.includes(email) ? '' : email;
};

export const getIntegrationNameValueBasedOnType = (type, integration) => {
  let result;

  switch (type.toUpperCase()) {
    case IntegrationType.DISCORD:
      result = integration.data.guildName;
      break;
    case IntegrationType.SLACK:
      result = integration.data.teamName;
      break;
    case IntegrationType.DISCOURSE:
      result = integration.data.apiUser;
      break;
    case IntegrationType.HUBSPOT:
    case IntegrationType.CRM_HUBSPOT:
    case IntegrationType.SALESFORCE:
    case IntegrationType.CONFLUENCE:
    case IntegrationType.NOTION:
      result = integration.data.domainName;
      break;
    case IntegrationType.ZENDESK:
      result = extractValueFromSubdomain(integration.data.subdomain, ALLOWED_PROTOCOLS_LIST);
      break;
    case IntegrationType.FRESHDESK:
      result = integration.data.subdomain;
      break;
    case IntegrationType.INTERCOM:
      result = integration.data.name;
      break;
    case IntegrationType.GOOGLE_DRIVE:
      result = integration.data.name;
      break;
    default:
      result = integration.data.userName;
  }

  return result;
};

export const getTypeDescription = (type) => {
  switch (type.toUpperCase) {
    case IntegrationType.CONFLUENCE:
      return 'Confluence space';
    case IntegrationType.ZENDESK:
      return 'Zendesk account';
    case IntegrationType.FRESHDESK:
      return 'Freshdesk account';
    case IntegrationType.NOTION:
      return 'Notion account';
    case IntegrationType.GOOGLE_DRIVE:
      return 'Google Drive account';
    default:
      return 'community';
  }
};

export const getModelNameMapper = {
  THREADO_OPEN_AI_GPT_4: 'Threado AI-OpenAI GPT4 (Default)',
  THREADO_AZURE_AI_GPT_4: 'Threado AI-AzureAI GPT4',
  OPEN_AI_GPT_4_8K: 'Open AI -gpt-4 - 8K',
  OPEN_AI_GPT_4o_128k: 'OpenAI - gpt-4o-128k',
  OPEN_AI_GPT_4_34K: 'OpenAI - gpt-4 - 34k',
  AZURE_AI_GPT_4_8K: 'Azure -gpt-4 - 8K',
  OPEN_AI_GPT_4_32K: 'Open AI -gpt-4 - 32K',
  AZURE_AI_GPT_4_32K: 'Azure -gpt-4 - 32K',
  OPEN_AI_GPT_35: 'Open AI -gpt-3.5 -turbo-16k',
  AZURE_AI_GPT_35: 'Azure -gpt-3.5 - turbo-16k',
  THREADO_OPEN_AI_GPT_4o_128K: 'Threado AI-OpenAI gpt-4o-128k'
};


export const MODELS_LIST_FOR_THREADO_AI = [
  'THREADO_OPEN_AI_GPT_4',
  'THREADO_AZURE_AI_GPT_4',
  'THREADO_OPEN_AI_GPT_4o_128K',
  'THREADO_TEXT_EMBEDDING_ADA_2',
  'THREADO_TEXT_EMBEDDING_3_LARGE',

];
export const MODELS_LIST_FOR_OPEN_AI = [
  'OPEN_AI_GPT_4_8K',
  'OPEN_AI_GPT_4o_128k',
  'OPEN_AI_GPT_4_34K',
  'OPEN_AI_GPT_4_32K',
  'OPEN_AI_GPT_35',
  'OPEN_AI_TEXT_EMBEDDING_ADA_2',
  'OPEN_AI_THREADO_TEXT_EMBEDDING_3_LARGE',
];

export const MODELS_LIST_FOR_AZURE = [
  'AZURE_AI_GPT_4_8K',
  'AZURE_AI_GPT_35',
  'AZURE_AI_TEXT_EMBEDDING_ADA_2',
  'AZURE_AI_THREADO_TEXT_EMBEDDING_3_LARGE',
];
export const getModelToImageMapper = {
  THREADO_OPEN_AI_GPT_4: threadoAILogo,
  THREADO_AZURE_AI_GPT_4: threadoAILogo,
  THREADO_OPEN_AI_GPT_4o_128K: threadoAILogo,
  OPEN_AI_GPT_4_8K: chatGptLogo,
  AZURE_AI_GPT_4_8K: azureLogo,
  OPEN_AI_GPT_4_32K: chatGptLogo,
  AZURE_AI_GPT_4_32K: azureLogo,
  OPEN_AI_GPT_35: chatGptLogo,
  AZURE_AI_GPT_35: azureLogo,
  OPEN_AI_GPT_4o_128k: chatGptLogo,
  OPEN_AI_GPT_4_34K: chatGptLogo
};

export const getEmbeddingModelNameMapper = {
  THREADO_TEXT_EMBEDDING_ADA_2: 'Threado text embedding-ada-2',
  THREADO_TEXT_EMBEDDING_3_LARGE: 'Threado AI text embedding-3-large (Default)',
  OPEN_AI_TEXT_EMBEDDING_ADA_2: 'Open AI text embedding-ada-2',
  OPEN_AI_THREADO_TEXT_EMBEDDING_3_LARGE: 'Open AI Threado text embedding-3-large',
  AZURE_AI_TEXT_EMBEDDING_ADA_2: 'Azure text embedding-ada-2',
  AZURE_AI_THREADO_TEXT_EMBEDDING_3_LARGE: 'Azure text embedding-3-large',
};

export const getEmbeddingModelToImageMapper = {
  THREADO_TEXT_EMBEDDING_ADA_2: threadoAILogo,
  THREADO_TEXT_EMBEDDING_3_LARGE: threadoAILogo,
  OPEN_AI_TEXT_EMBEDDING_ADA_2: chatGptLogo,
  OPEN_AI_THREADO_TEXT_EMBEDDING_3_LARGE: chatGptLogo,
  AZURE_AI_TEXT_EMBEDDING_ADA_2: azureLogo,
  AZURE_AI_THREADO_TEXT_EMBEDDING_3_LARGE: azureLogo,
};

export const StyledFormControlLabelForAiModel = styled((props) => <FormControlLabel {...props} />)(
  ({ checked }) => ({
    '&.MuiFormControlLabel-root': checked
      ? {
          margin: '0px',
          marginBottom: '8px',
          color: '#4E4E4E',
          fontSize: '14px',
          fontWeight: 600,
          width: 'fit-content',
        }
      : {
          borderRadius: '4px',
          margin: '0px',
          marginBottom: '8px',
          color: '#4E4E4E',
          fontSize: '14px',
          fontWeight: 500,
          width: 'fit-content',
        },

    '&.MuiFormControlLabel-root:hover': {
      fontWeight: 600,
      color: 'red',
    },

    '.MuiFormControlLabel-label': {
      color: '#4E4E4E',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    '.MuiButtonBase-root': checked
      ? {
          color: 'rgba(105, 109, 236, 1)',
        }
      : {
          color: '#B1B3E2',
        },
  }),
);
