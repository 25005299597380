// Get all mentions
export const GET_ALL_MENTIONS = 'GET_ALL_MENTIONS';
export const SET_ALL_MENTIONS = 'SET_ALL_MENTIONS';
export const HANDLE_GET_ALL_MENTIONS = 'HANDLE_GET_ALL_MENTIONS';
// Get all tags
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const SET_ALL_TAGS = 'SET_ALL_TAGS';
export const HANDLE_ALL_TAGS = 'HANDLE_ALL_TAGS';
// Messages
export const SET_INTIAL_MESSAGE = 'SET_INTIAL_MESSAGE';
export const IS_INITIAL_MESSAGE_SET = 'IS_INITIAL_MESSAGE_SET';
