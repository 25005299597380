import Api from 'layers/interceptor/interceptor';
import {
  OPEN_DATA_SOURCE_SIDEBAR,
  CLOSE_DATA_SOURCE_SIDEBAR,
  SET_SELECTED_DATA_SOURCE,
  CLEAR_SELECTED_DATA_SOURCE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_URL,
  SET_TRAINING_DATA_PROGRESS_LOADING,
  GET_TRAINING_DATA_PROGRESS_DETAILS_FILES,
  GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM,
  GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT,
  GET_TRAINING_DATA_PROGRESS_DETAILS_SALESFORCE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD,
  GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_SALESFORCE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT,
  GET_TRAINING_PAGE_DATA_DETAILS,
  SET_TRAINING_PAGE_DATA_LOADING,
  GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS,
  SET_IS_DIRTY_FLAG_FOR_POLLING,
  GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE,
} from 'redux/trainingProvider/types.js';
import {
  downloadFileByPathLinkApiUrl,
  getChannelDetailsTrainingUrl,
  getChannelDetailsUrl,
  trainGoogleDriveSourcePostUrl,
} from '../../../constant/apiConstants';
import { SET_CHANNEL_DETAILS_TRAINING, SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION } from '../types';
import { addFileByUploadDataSourceApiUrl, getTrainingDataProgressApiUrl, getTrainingPageDataDetailsApiUrl } from '../apiConstant';
import { TrainingDataSourceType } from 'pages/train/util';
import { format } from 'date-fns';

export const setSelectedDataSource = (selectedDataSource) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DATA_SOURCE,
    payload: selectedDataSource,
  });
};

export const clearSelectedDataSource = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_DATA_SOURCE,
  });
};

export const setSidebarOpenCloseStatus = (isDataSourceSideBarOpen) => (dispatch) => {
  if (isDataSourceSideBarOpen) {
    dispatch({
      type: OPEN_DATA_SOURCE_SIDEBAR,
    });
  } else {
    dispatch({
      type: CLOSE_DATA_SOURCE_SIDEBAR,
    });
    dispatch(setIsDirtyFlagForPollingAction(false));
  }
};

export const getChannelListForTraining = (communityId, addToast) => (dispatch) => {
  Api.get(getChannelDetailsTrainingUrl(communityId))
    .then((res) => {
      dispatch({
        type: SET_CHANNEL_DETAILS_TRAINING,
        payload: res,
      });
    })
    .catch(() => {
      addToast('Facing some issues from our End. Try again Later!', { appearance: 'error' });
    });
};

export const setSidebarOpenSourceFromIntegration = (payload) => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION,
    payload: payload,
  });
};

export const getTrainingDataProgressByProvider =
  (communityId, provider, addToast, isPollingFetch) => (dispatch) => {
    if (!isPollingFetch) {
      dispatch(setTrainingDataProgressLoading(true));
    }
    Api.get(getTrainingDataProgressApiUrl(communityId, provider))
      .then((res) => {
        switch (provider) {
          case TrainingDataSourceType.URL:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_URL,
              payload: res,
            });
            break;
          case TrainingDataSourceType.NOTION:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION,
              payload: res,
            });
            break;
          case TrainingDataSourceType.CONFLUENCE:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE,
              payload: res,
            });
            break;
          case TrainingDataSourceType.FILES:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_FILES,
              payload: res,
            });
            break;
          case TrainingDataSourceType.GOOGLE_DRIVE:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE,
              payload: res,
            });
            break;
          case TrainingDataSourceType.ZENDESK:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK,
              payload: res,
            });
            break;
          case TrainingDataSourceType.FRESHDESK:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK,
              payload: res,
            });
            break;
          case TrainingDataSourceType.INTERCOM:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM,
              payload: res,
            });
            break;
          case TrainingDataSourceType.HUBSPOT:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT,
              payload: res,
            });
            break;
          case TrainingDataSourceType.CRM_HUBSPOT:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT,
              payload: res,
            });
            break;
          case TrainingDataSourceType.SALESFORCE:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_SALESFORCE,
              payload: res,
            });
            break;
          case TrainingDataSourceType.SLACK:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK,
              payload: res,
            });
            break;
          case TrainingDataSourceType.DISCORD:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD,
              payload: res,
            });
            break;
          case TrainingDataSourceType.MS_TEAMS:
            dispatch({
              type: GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS,
              payload: res,
            });
            break;
        }
      })
      .catch(() => {
        if (!isPollingFetch) {
          addToast('Error occured in fetching data source progress. Try again Later!', {
            appearance: 'error',
          });
        }
      })
      .finally(() => {
        dispatch(setTrainingDataProgressLoading(false));
      });
  };

export const setTrainingDataProgressLoading = (payload) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_DATA_PROGRESS_LOADING,
    payload: payload,
  });
};

export const trainGoogleDriveDataSourceAction =
  (integrationId, communityId, openSidebar, payload, addToast) => (dispatch) => {
    Api.post(trainGoogleDriveSourcePostUrl(integrationId), payload)
      .then((res) => {
        dispatch(getTrainingDataProgressByProvider(communityId, 'GOOGLE_DRIVE', addToast));
        openSidebar();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log('finally');
      });
  };

export const getTrainingPageDetailsDataAction = (workspaceId, addToast) => (dispatch) => {
  dispatch(setTrainingPageDataLoading(true));
  Api.get(getTrainingPageDataDetailsApiUrl(workspaceId))
    .then((res) => {
      dispatch({
        type: GET_TRAINING_PAGE_DATA_DETAILS,
        payload: res,
      });
    })
    .catch((err) => {
      addToast(`Error occured in fetching data source details. Try again Later!`, {
        appearance: 'error',
      });
    })
    .finally(() => {
      dispatch(setTrainingPageDataLoading(false));
    });
};

export const setTrainingPageDataLoading = (payload) => (dispatch) => {
  dispatch({
    type: SET_TRAINING_PAGE_DATA_LOADING,
    payload: payload,
  });
};

export const setTrainingPageDetailsDataByWSEvent = (payload) => (dispatch) => {
  dispatch({
    type: GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS,
    payload: JSON.parse(payload?.data),
  });
};

export const setIsDirtyFlagForPollingAction = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_DIRTY_FLAG_FOR_POLLING,
    payload: payload,
  });
};

export const addFilesByUplaodDataSourceAction =
  (formData, workspacedId, setLoading, addToast) => (dispatch) => {
    setLoading(true);
    Api.post(addFileByUploadDataSourceApiUrl(workspacedId), formData, {
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        dispatch({
          type: GET_TRAINING_DATA_PROGRESS_DETAILS_FILES,
          payload: res?.response,
        });
        addToast(`Training started succesfully`, {
          appearance: 'success',
        });
      })
      .catch((err) => {
        addToast(`Error occured in uploading files. Try again Later!`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

export const downloadFileFromPathAction =
  (communityId, filePath, addToast) => (dispatch) => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd');
    Api.get(downloadFileByPathLinkApiUrl(communityId, filePath, formattedDate))
      .then((res) => {
        addToast(`File has been successfully sent to your email.`, {
          appearance: 'success',
        });
      })
      .catch((err) => {
        addToast(`Error occured in downloading file. Try again Later!`, {
          appearance: 'error',
        });
      });
  };

  