// Action types for managing the data source sidebar in the application

// Open the data source sidebar
export const OPEN_DATA_SOURCE_SIDEBAR = 'OPEN_DATA_SOURCE_SIDEBAR';

// Close the data source sidebar
export const CLOSE_DATA_SOURCE_SIDEBAR = 'CLOSE_DATA_SOURCE_SIDEBAR';

// Set the selected data source
export const SET_SELECTED_DATA_SOURCE = 'SET_SELECTED_DATA_SOURCE';

// Clear the selected data source
export const CLEAR_SELECTED_DATA_SOURCE = 'CLEAR_SELECTED_DATA_SOURCE';

export const SET_CHANNEL_DETAILS_TRAINING = 'SET_CHANNEL_DETAILS_TRAINING';

export const SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION = 'SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_URL = 'GET_TRAINING_DATA_PROGRESS_DETAILS_URL';
export const GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION';
export const GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_FILES = 'GET_TRAINING_DATA_PROGRESS_DETAILS_FILES';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK';
export const GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_SALESFORCE =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_SALESFORCE';

export const GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK = 'GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK';
export const GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD';
export const GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS =
  'GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS';

export const SET_TRAINING_DATA_PROGRESS_LOADING = 'SET_TRAINING_DATA_PROGRESS_LOADING';

export const GET_TRAINING_PAGE_DATA_DETAILS = 'GET_TRAINING_PAGE_DATA_DETAILS';
export const SET_TRAINING_PAGE_DATA_LOADING = 'SET_TRAINING_PAGE_DATA_LOADING';
export const GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS = 'GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS';

export const SET_IS_DIRTY_FLAG_FOR_POLLING = 'SET_IS_DIRTY_FLAG_FOR_POLLING';
