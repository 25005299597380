export const SET_COMMUNITY_DATA_STATE = 'SET_COMMUNITY_DATA_STATE';
export const LOAD_COMMUNITY_NOTIFICATIONS = 'LOAD_COMMUNITY_NOTIFICATIONS';
export const UPDATE_COMMUNITY_NOTIFICATIONS_COUNT = 'UPDATE_COMMUNITY_NOTIFICATIONS_COUNT';
export const SELECT_COMMUNITY = 'SELECT_COMMUNITY';
export const REFRESH_COMMUNITY = 'REFRESH_COMMUNITY';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const HANDLE_COMMUNITY_ERROR = 'HANDLE_COMMUNITY_ERROR';
export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING';
export const MARKED_NOTIFICATION = 'MARKED_NOTIFICATION';
export const SET_DATA_LOAD_NOTIFICATION = 'SET_DATA_LOAD_NOTIFICATION';
export const RESET_DATA_LOAD_NOTIFICATION = 'RESET_DATA_LOAD_NOTIFICATION';

export const GET_ALL_COMMUNITIES_START = 'GET_ALL_COMMUNITIES_START';
export const GET_ALL_COMMUNITIES_SUCCESS = 'GET_ALL_COMMUNITIES_SUCCESS';
export const GET_ALL_COMMUNITIES_FAIL = 'GET_ALL_COMMUNITIES_FAIL';

export const SAVE_COMMUNITY_START = 'SAVE_COMMUNITY_START';
export const SAVE_COMMUNITY_SUCCESS = 'SAVE_COMMUNITY_SUCCESS';
export const SAVE_COMMUNITY_FAIL = 'SAVE_COMMUNITY_FAIL';

export const EDIT_COMMUNITY_CLICKED = 'EDIT_COMMUNITY_CLICKED';
export const EDIT_COMMUNITY_CANCEL = 'EDIT_COMMUNITY_CANCEL';

export const DISCORD_SHOW_MODAL = 'DISCORD_SHOW_MODAL';
export const DISCORD_HIDE_MODAL = 'DISCORD_HIDE_MODAL';

export const SLACK_SHOW_MODAL = 'SLACK_SHOW_MODAL';
export const SLACK_HIDE_MODAL = 'SLACK_HIDE_MODAL';

export const ZOOM_SHOW_MODAL = 'ZOOM_SHOW_MODAL';
export const ZOOM_HIDE_MODAL = 'ZOOM_HIDE_MODAL';

export const TWITTER_SHOW_MODAL = 'TWITTER_SHOW_MODAL';
export const TWITTER_HIDE_MODAL = 'TWITTER_HIDE_MODAL';

export const COMMUNITY_DEL_MODAL_SHOW = 'COMMUNITY_DEL_MODAL_SHOW';
export const COMMUNITY_DEL_MODAL_HIDE = 'COMMUNITY_DEL_MODAL_SHOW';

export const LOAD_MEMBERS_AND_CHANNELS_START = 'LOAD_MEMBERS_AND_CHANNELS_START';
export const LOAD_MEMBERS_AND_CHANNELS_SUCCESS = 'LOAD_MEMBERS_AND_CHANNELS_SUCCESS';
export const LOAD_MEMBERS_AND_CHANNELS_FAILURE = 'LOAD_MEMBERS_AND_CHANNELS_FAILURE';

export const LOAD_MESSAGES_AND_REACTIONS_START = 'LOAD_MESSAGES_AND_REACTIONS_START';
export const LOAD_MESSAGES_AND_REACTIONS_SUCCESS = 'LOAD_MESSAGES_AND_REACTIONS_SUCCESS';
export const LOAD_MESSAGES_AND_REACTIONS_FAILURE = 'LOAD_MESSAGES_AND_REACTIONS_FAILURE';

export const LOAD_SEGMENTS_START = 'LOAD_SEGMENTS_START';
export const LOAD_SEGMENTS_SUCCESS = 'LOAD_SEGMENTS_SUCCESS';
export const LOAD_SEGMENTS_FAILURE = 'LOAD_SEGMENTS_FAILURE';

//Deleting discord data
export const DELETE_DISCORD_START = 'DELETE_DISCORD_START';
export const DELETE_DISCORD_SUCCESS = 'DELETE_DISCORD_SUCCESS';
export const DELETE_DISCORD_FAIL = 'DELETE_DISCORD_FAIL';

//Deleting community data
export const DELETE_COMMUNITY_START = 'DELETE_COMMUNITY_START';
export const DELETE_COMMUNITY_SUCCESS = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_FAIL = 'DELETE_COMMUNITY_FAIL';

export const SET_INTEGRATION_CHANNEL_CONFIG = 'LOAD_INTEGRATION_CHANNEL_CONFIG';
export const TOOGLE_INTEGRATION_CHANNEL_STATUS = 'TOOGLE_INTEGRATION_CHANNEL_STATUS';
export const SELECT_ALL_INTEGRATION_CHANNELS = 'SELECT_ALL_INTEGRATION_CHANNELS';
export const SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS = 'SELECT_ALL_PRIVATE_INTEGRATION_CHANNELS';
export const SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS = 'SELECT_ALL_PUBLIC_INTEGRATION_CHANNELS';
export const UNSELECT_ALL_INTEGRATION_CHANNELS = 'UNSELECT_ALL_INTEGRATION_CHANNELS';

// Show data loading banner
export const SHOW_DATA_LOADING_BANNER = 'SHOW_DATA_LOADING_BANNER';
export const SET_LAST_SYNC_TIME = 'SET_LAST_SYNC_TIME';

// Discourse Integration data
export const GET_DISCOURSE_INTEGRATION_SECRET = 'GET_DISCOURSE_INTEGRATION_SECRET';
export const GET_DISCOURSE_INTEGRATION_URL = 'GET_DISCOURSE_INTEGRATION_URL';
export const SET_DISCOURSE_INTEGRATION_LOADING = 'SET_DISCOURSE_INTEGRATION_LOADING';

export const SET_COMMUNITY_PLATFORM_DETAILS = 'SET_COMMUNITY_PLATFORM_DETAILS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const INDIVISUALCHANNELLOAD = 'INDIVISUALCHANNELLOAD';
export const RESET_INDIVISUALCHANNELLOAD = 'RESET_INDIVISUALCHANNELLOAD';
export const SET_MEMBER_COUNT = 'SET_MEMBER_COUNT';

export const SET_DISCORD_CUSTOM_BOT = 'SET_DISCORD_CUSTOM_BOT';
export const SET_COMMUNITY_DETAILS = 'SET_COMMUNITY_DETAILS';

export const GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS =
  'GET_DISCORD_ONBOARDING_ORGANIZATION_DETAILS';

export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';
export const SET_COMMUNITY_DATA_HUBSPOT_SYNC = 'SET_COMMUNITY_DATA_HUBSPOT_SYNC';
export const SET_SIGNUP_COMMUNITY_DETAILS = 'SET_SIGNUP_COMMUNITY_DETAILS'

export const SET_SLACK_CUSTOM_BOT = 'SET_SLACK_CUSTOM_BOT';

export const SET_AI_MODEL_SETTINGS = 'SET_AI_MODEL_SETTINGS';
export const GET_AI_MODELS_LIST = 'GET_AI_MODELS_LIST';

export const SET_EMBEDDING_AI_MODEL_SETTINGS = 'SET_EMBEDDING_AI_MODEL_SETTINGS';
export const GET_EMBEDDING_AI_MODELS_LIST = 'GET_EMBEDDING_AI_MODELS_LIST';